import { classNames } from "@/utils/styling-utils";

interface AlertBubbleProps {
  alert?: number;
  raised?: boolean;
}

export const AlertBubble = ({ alert, raised }: AlertBubbleProps) => (
  <div aria-hidden="true" className={classNames("alert-bubble", raised && "alert-bubble--raised")}>
    {alert ?? "!"}
  </div>
);
