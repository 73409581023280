/* eslint-disable @next/next/no-img-element */
import {
  ArrowLeftCircleIcon as ArrowLeftCircleHeroicon,
  ArrowRightCircleIcon as ArrowRightCircleHeroicon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
  ClockIcon as ClockHeroicon,
  DocumentIcon as DocumentHeroicon,
  EnvelopeIcon as EnvelopeHeroicon,
  ExclamationCircleIcon,
  EyeIcon as EyeHeroicon,
  EyeSlashIcon as EyeSlashHeroicon,
  HeartIcon as HeartOutlineHeroicon,
  InformationCircleIcon,
  LockClosedIcon,
  PhoneIcon as PhoneHeroicon,
  MapPinIcon as MapPinHeroIconOutline,
  UsersIcon as UsersHeroicon,
  CalendarDaysIcon as CalendarDaysHeroIcon,
  CalendarIcon as CalendarHeroIcon,
  QueueListIcon
} from "@heroicons/react/24/outline";
import {
  AcademicCapIcon,
  ArrowLeftIcon as ArrowLeftHeroicon,
  ArrowRightIcon as ArrowRightHeroicon,
  CheckBadgeIcon as CheckBadgeHeroicon,
  CheckIcon as CheckHeroicon,
  CheckCircleIcon as CheckCircleHeroicon,
  ChevronDownIcon as ChevronDownHeroicon,
  ChevronRightIcon as ChevronRightHeroicon,
  ChevronUpIcon as ChevronUpHeroicon,
  HeartIcon as HeartSolidHeroicon,
  MapPinIcon as MapPinHeroicon,
  PencilIcon as PencilHeroicon,
  TrashIcon as TrashHeroicon,
  UserCircleIcon as UserCircleHeroicon
} from "@heroicons/react/24/solid";
import { Icon } from "./icon";
import { AlertBubble } from "../ui/alert-bubble";
import { classNames } from "@/utils/styling-utils";

interface IconsNameProps {
  name?: string; // accessible name
}

export interface IconsProps extends IconsNameProps {
  alert?: number;
  clickThrough?: boolean;
  color?: "green" | "white" | "gray" | "blue";
  size?: "x-small" | "small" | "large" | "x-large" | "xx-large";

  /**
   * Display icon without touch target area
   */
  stripIcon?: boolean;
}

interface IconPropsCustomizable extends IconsProps {
  customColorHex?: string;
  customStrokeWidth?: string;
}

export const AlertIcon = ({ alert, name, size, stripIcon }: IconsProps) => (
  <Icon className="alert-icon" name={name} size={size} stripIcon={stripIcon}>
    <AlertBubble alert={alert} />
  </Icon>
);

export const ArrowLeftIcon = ({ name, size }: IconsProps) => (
  <Icon className="arrow-left-icon" name={name} size={size}>
    <ArrowLeftHeroicon />
  </Icon>
);

export const ArrowRightIcon = ({ name, size }: IconsProps) => (
  <Icon className="arrow-right-icon" name={name} size={size}>
    <ArrowRightHeroicon />
  </Icon>
);

export const ArrowLeftCircleIcon = ({ name, size }: IconsProps) => (
  <Icon className="arrow-left-circle-icon" name={name} size={size}>
    <ArrowLeftCircleHeroicon />
  </Icon>
);

export const ArrowRightCircleIcon = ({ name, size }: IconsProps) => (
  <Icon className="arrow-right-circle-icon" name={name} size={size}>
    <ArrowRightCircleHeroicon />
  </Icon>
);

export const CalendarIcon = ({ name, size, stripIcon, color }: IconsProps) => (
  <Icon className="calendar-icon" name={name} size={size} stripIcon={stripIcon} color={color}>
    <CalendarHeroIcon />
  </Icon>
);

export const CalendarDaysIcon = ({ name, size, stripIcon, color }: IconsProps) => (
  <Icon className="calendar-days-icon" name={name} size={size} stripIcon={stripIcon} color={color}>
    <CalendarDaysHeroIcon />
  </Icon>
);

export const CheckBadgeIcon = ({ name }: IconsNameProps) => (
  <Icon className="check-badge-icon" name={name}>
    <CheckBadgeHeroicon />
  </Icon>
);

export const CheckIcon = ({ color, name, size, stripIcon }: IconsProps) => (
  <Icon className="check-icon" color={color} name={name} size={size} stripIcon={stripIcon}>
    <CheckHeroicon />
  </Icon>
);

export const CheckIconCustomizable = ({
  name,
  size,
  stripIcon,
  customColorHex,
  customStrokeWidth
}: IconPropsCustomizable) => (
  <Icon className="check-icon-any-color" name={name} size={size} stripIcon={stripIcon}>
    <CheckHeroicon stroke={customColorHex} fill={customColorHex} strokeWidth={customStrokeWidth} />
  </Icon>
);

export const CheckCircleIcon = ({ name, size, color }: IconsProps) => (
  <Icon className="check-circle-icon" name={name} size={size} color={color}>
    <CheckCircleHeroicon />
  </Icon>
);

export const ChevronDownIcon = ({ clickThrough, name, size }: IconsProps) => (
  <Icon className="chevron-down-icon" clickThrough={clickThrough} name={name} size={size}>
    <ChevronDownHeroicon />
  </Icon>
);

export const ChevronRightIcon = ({ clickThrough, name, size, color }: IconsProps) => (
  <Icon className="chevron-right-icon" clickThrough={clickThrough} name={name} size={size} color={color}>
    <ChevronRightHeroicon />
  </Icon>
);

export const ChevronUpIcon = ({ clickThrough, name, size }: IconsProps) => (
  <Icon className="chevron-up-icon" clickThrough={clickThrough} name={name} size={size}>
    <ChevronUpHeroicon />
  </Icon>
);

export const ClockIcon = ({ name, stripIcon }: IconsProps) => (
  <Icon className="clock-icon" name={name} stripIcon={stripIcon}>
    <ClockHeroicon />
  </Icon>
);

export const CopyIcon = ({ name }: IconsNameProps) => (
  <Icon className="copy-icon" name={name}>
    <ClipboardDocumentIcon />
  </Icon>
);

export const CopyCheckIcon = ({ name }: IconsNameProps) => (
  <Icon className="copy-check-icon" name={name}>
    <ClipboardDocumentCheckIcon />
  </Icon>
);

export const DocumentIcon = ({ name, stripIcon }: IconsProps) => (
  <Icon className="document-icon" name={name} stripIcon={stripIcon}>
    <DocumentHeroicon />
  </Icon>
);

export const EnvelopeIcon = ({ name }: IconsNameProps) => (
  <Icon className="envelope-icon" name={name}>
    <EnvelopeHeroicon />
  </Icon>
);

export const ExclamationIcon = ({ name, size }: IconsProps) => (
  <Icon className="exclamation-circle-icon" name={name} size={size}>
    <ExclamationCircleIcon />
  </Icon>
);

export const EyeIcon = ({ name }: IconsNameProps) => (
  <Icon className="eye-icon" name={name} size="small">
    <EyeHeroicon />
  </Icon>
);

export const EyeSlashIcon = ({ name }: IconsNameProps) => (
  <Icon className="eye-slash-icon" name={name} size="small">
    <EyeSlashHeroicon />
  </Icon>
);

export const FacebookIcon = ({ name, stripIcon }: IconsProps) => (
  <Icon className="facebook-icon" name={name} stripIcon={stripIcon}>
    <img alt="Facebook-ikon" src="/images/some/facebook.png" />
  </Icon>
);

export const GoogleCalendarIcon = ({ name }: IconsNameProps) => (
  <Icon className="google-calendar-icon" name={name}>
    <img alt="Google Kalender-ikon" src="/images/components/google_calendar_icon.png" />
  </Icon>
);

export const HeartIconOutline = ({ name }: IconsNameProps) => (
  <Icon className="heart-icon--outline" name={name}>
    <HeartOutlineHeroicon />
  </Icon>
);

export const HeartIconSolid = ({ name }: IconsNameProps) => (
  <Icon className="heart-icon--solid" name={name}>
    <HeartSolidHeroicon />
  </Icon>
);

export const InfoCircleIcon = ({ name, stripIcon }: IconsProps) => (
  <Icon className="info-circle-icon" name={name} stripIcon={stripIcon}>
    <InformationCircleIcon />
  </Icon>
);

export const InstagramIcon = ({ name, stripIcon }: IconsProps) => (
  <Icon className="instagram-icon" name={name} stripIcon={stripIcon}>
    <img alt="Instagram-ikon" src="/images/some/instagram.png" />
  </Icon>
);

export const LinkedInIcon = ({ name, stripIcon }: IconsProps) => (
  <Icon className="linkedin-icon" name={name} stripIcon={stripIcon}>
    <img alt="LinkedIn-ikon" src="/images/some/linkedin.png" />
  </Icon>
);

export const LockClosedIconOutline = ({ name }: IconsNameProps) => (
  <Icon className="info-circle-icon" name={name}>
    <LockClosedIcon />
  </Icon>
);

export const MapPinIcon = ({ name, size, stripIcon, color }: IconsProps) => (
  <Icon className="map-pin-icon" name={name} size={size} stripIcon={stripIcon} color={color}>
    <MapPinHeroicon />
  </Icon>
);

export const MapPinIconOutline = ({ name, size, stripIcon, color }: IconsProps) => (
  <Icon className="map-pin-icon-outline" name={name} size={size} stripIcon={stripIcon} color={color}>
    <MapPinHeroIconOutline />
  </Icon>
);
export const MobileMenuIcon = ({ name }: IconsNameProps) => (
  <Icon className="mobile-menu-icon" name={name}>
    <img alt="Mobilmeny-ikon" src="/images/components/mobile-menu-icon.svg" />
  </Icon>
);

export const PauseCircleIcon = ({ name }: IconsNameProps) => (
  <Icon className="pause-circle-icon" name={name}>
    <img alt="Pause-ikon" src="/images/components/pause-icon.svg" />
  </Icon>
);

export const PencilIcon = ({ name }: IconsNameProps) => (
  <Icon className="pencil-icon" name={name}>
    <PencilHeroicon />
  </Icon>
);

export const PhoneIcon = ({ name }: IconsNameProps) => (
  <Icon className="phone-icon" name={name}>
    <PhoneHeroicon />
  </Icon>
);

export const PlayCircleIcon = ({ name }: IconsNameProps) => (
  <Icon className="play-circle-icon" name={name}>
    <img alt="Avspillings-ikon" src="/images/components/play-icon.svg" />
  </Icon>
);

export const PlaceholderIcon = ({ name }: IconsNameProps) => <Icon className="placeholder-icon" name={name} />;

export const PTLevelIcon = ({ name, size }: IconsProps) => (
  <Icon className="pt-level-icon" name={name} size={size}>
    <AcademicCapIcon />
  </Icon>
);

export const SearchIcon = ({ name, size }: IconsProps) => (
  <Icon className="search-icon" name={name} size={size}>
    <img alt="Søke-ikon" src="/images/components/search-icon.svg" />
  </Icon>
);

export const SearchIconGray = ({ name, size }: IconsProps) => (
  <Icon className="search-icon" name={name} size={size}>
    <img alt="Søke-ikon" src="/images/components/search-icon-gray.svg" />
  </Icon>
);

export const ShareIcon = ({ name }: IconsNameProps) => (
  <Icon className="share-icon" name={name}>
    <img alt="Dele-ikon" src="/images/components/share-icon.svg" />
  </Icon>
);

export const TikTokIcon = ({ name, stripIcon }: IconsProps) => (
  <Icon className="tiktok-icon" name={name} stripIcon={stripIcon}>
    <img alt="TikTok-ikon" src="/images/some/tiktok.png" />
  </Icon>
);

export const TrashIcon = ({ name, size }: IconsProps) => (
  <Icon className="trash-icon" name={name} size={size}>
    <TrashHeroicon />
  </Icon>
);

//does not support color param
export const UserIconOutline = ({ name, size }: IconsProps) => (
  <Icon className="user-icon--outline" name={name} size={size}>
    <img alt="Bruker-ikon" src="/images/components/user-icon-outline.svg" />
  </Icon>
);

export const UserIconOutlineGray = ({ name, size }: IconsProps) => (
  <Icon className="user-icon--outline" name={name} size={size}>
    <img alt="Bruker-ikon" src="/images/components/user-icon-outline-gray.svg" />
  </Icon>
);

//does not support color param
export const UserIconSolid = ({ alert, name, size }: IconsProps) => (
  <Icon alert={alert} className="user-icon--solid" name={name} size={size}>
    <img alt="Bruker-ikon" src="/images/components/user-icon-solid.svg" />
  </Icon>
);

export const UsersIcon = ({ name, stripIcon, color, size }: IconsProps) => (
  <Icon className="users-icon" name={name} stripIcon={stripIcon} color={color} size={size}>
    <UsersHeroicon />
  </Icon>
);

export const UserCircleIcon = ({ name, stripIcon, color, size }: IconsProps) => (
  <Icon className="user-circle-icon" name={name} stripIcon={stripIcon} color={color} size={size}>
    <UserCircleHeroicon />
  </Icon>
);

export const WaitlistIcon = ({ name }: IconsNameProps) => (
  <Icon className="waitlist-icon" name={name}>
    <QueueListIcon />
  </Icon>
);

//does not support color param
export const XIcon = ({ name, size }: IconsProps) => (
  <Icon className="x-icon" name={name} size={size}>
    <img alt="Lukk mobilmeny-ikon" src="/images/components/x-icon.svg" />
  </Icon>
);

export const XIconGray = ({ name, size }: IconsProps) => (
  <Icon className="x-icon-gray" name={name} size={size}>
    <img alt="Lukk mobilmeny-ikon" src="/images/components/x-icon-gray.svg" />
  </Icon>
);

//does not support color param
export const ArrowDownRightIcon = ({ name, size }: IconsProps) => (
  <Icon className="arrow-down-right-icon" name={name} size={size}>
    <img alt="Pil ned og til høyre ikon" src="/images/components/arrow-down-right-gray.svg" />
  </Icon>
);

interface IconMenuProps {
  isActive?: boolean;
}

export const IconMenu = ({ isActive }: IconMenuProps) => (
  <i className={classNames("icon-menu", isActive && "icon-menu--active")}>
    <span className="icon-menu__bar" />
    <span className="icon-menu__bar" />
    <span className="icon-menu__bar" />
  </i>
);
