import { ReactNode } from "react";

import { classNames } from "@/utils/styling-utils";
import { IconsProps } from "./icons";
import { VisuallyHidden } from "../layout/visually-hidden";
import { AlertBubble } from "../ui/alert-bubble";

interface IconProps extends IconsProps {
  children?: ReactNode;
  className: string;
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }

  return `icon__${property}--${value}`;
};

export const Icon = ({ alert, children, clickThrough, color, className, name, size, stripIcon }: IconProps) => {
  const cssColorClass = cssClasses("color", color);
  const cssSizeClass = cssClasses("size", size);

  return (
    <div
      className={classNames(
        "icon",
        className,
        cssColorClass,
        cssSizeClass,
        clickThrough && `icon--click-through`,
        stripIcon && `icon--stripped`
      )}
    >
      {name && <VisuallyHidden>{name}</VisuallyHidden>}
      {children}
      {alert && <AlertBubble alert={alert} />}
    </div>
  );
};
